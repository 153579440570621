<messages>["./BookEntry"]</messages>

<template>
  <base-layout mw1>
    <v-col cols="12">
      <!-- "book entry does not exist" alert -->
      <v-alert
        type="error"
        :value="error">
        {{ $t ('label.invalidBookEntry', { id }) }}
      </v-alert>

      <template v-if="isLoading">
        <v-progress-linear
          indeterminate
          class="load-progress"
          color="primary"/>
      </template>

      <!-- book entry details -->
      <v-card v-if="bookEntry">
        <v-card-title primary-title>
          <v-row>
            <v-col>
              <div>
                <div>
                  <span
                    v-t="`actions.${bookEntry.action}`"
                    class="text-h5"/>
                </div>
                <div>
                  <span
                    :class="[{'red--text': bookEntry.amount < 0}, 'cgwng-subheading']"
                    v-text="`${formatMoneyAmount (bookEntry.amount, bookEntry.currency)}`"/>
                </div>
                <div
                  v-if="bookEntry.registryType"
                  class="cgwng-subheading">
                  <registry-type-name :value="bookEntry.registryType" cut-tlds :max-tlds="2"/>
                  <v-spacer/>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-col v-if="bookEntry.orderId" class="shrink">
            <order-dialog
              :order-id="bookEntry.orderId"
              :object-id="bookEntry.id"
              type="bookEntry">
              <template #activator="{ on }">
                <v-btn
                  v-t="'general.button.showOrder'"
                  small
                  class="ma-0 elevation-0"
                  v-on="on"/>
              </template>
            </order-dialog>
          </v-col>
        </v-card-title>

        <v-card-text class="px-4 pb-4">
          <name-value-block :items="basicData"/>
        </v-card-text>
        <v-card-text v-if="(bundle && bundle.length) || isLoadingBundle" class="px-4 pb-4">
          <v-card-title primary-title>
            <div>
              <span
                v-t="`label.bundle`"
                class="text-h5"/>
            </div>
          </v-card-title>
          <bundle-list :book-entry-data="bundle" :loading="isLoadingBundle"/>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <back-button/>
          <v-btn
            v-if="bookEntry.refundable"
            v-t="'label.refund'"
            color="primary"
            :loading="isLoadingRefund"
            @click="onRefundClick"/>
          <refund-dialog
            v-model="refundDialog"
            :is-loading="isLoadingRefund"
            :refund-item="bookEntry"
            :cancel-action="cancelRefund"
            @ok="onRefund"/>
        </v-card-actions>

        <v-card-text>
          <invalid-warning v-if="showInvalidWarning"/>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'

  import BaseLayout from '@/app/core/components/BaseLayout'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import RegistryTypeLink from '@/app/core/components/RegistryTypeLink'
  import RegistryTypeName from '@/app/core/components/RegistryTypeName'
  import ClientLink from '@/app/core/components/ClientLink'
  import OrderDialog from '@/app/core/components/Order/OrderDialog'
  import BackButton from '@/app/core/components/BackButton'

  import BookEntryLink from './components/BookEntryLink'
  import ConversionInfo from './components/ConversionInfo'
  import VatInfo from './components/VatInfo'
  import BalancesInfo from './components/BalancesInfo'
  import RefundDialog from './components/RefundDialog'
  import InvalidWarning from './components/InvalidWarning'
  import BundleList from './components/BundleList'

  import {invalidReferenceDate as referenceDate} from './constants'

  export default {
    name: 'BookEntryView',

    components: {
      BaseLayout,
      NameValueBlock,
      RegistryTypeName,
      OrderDialog,
      BackButton,
      RefundDialog,
      InvalidWarning,
      BundleList
    },

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        bookEntry: null,
        isLoading: false,
        isLoadingRefund: false,
        error: false,
        refundDialog: false,
        bundle: [],
        isLoadingBundle: false
      //   sampleExchange: {
      //     originalAmount: {
      //       value: 50,
      //       currency: 'CHF'
      //     },
      //     convertedAmount: {
      //       value: 70,
      //       currency: 'USD'
      //     },
      //     appliedRate: 1.56
      //   }
      }
    },

    computed: {
      ...mapGetters ({
        permissions: 'auth/permissions'
      }),
      showInvalidWarning () {
        return this.bookEntry.creationDate < referenceDate
      },

      basicData () {
        return [
          {
            name: this.$t ('label.action'),
            value: this.$t (`actions.${this.bookEntry.action}`)
          },
          ...this.bookEntry.registryType
            ? [{
              name: this.$t ('label.registryType'),
              value: true,
              slotValue: this.$createElement (RegistryTypeLink, {
                props: {
                  value: this.bookEntry.registryType,
                  short: true
                }
              })
            }]
            : [],
          {
            name: this.$t ('label.amount'),
            value: this.formatMoneyAmount (
              this.bookEntry.amount,
              this.bookEntry.currency
            ),
            valueClasses: [
              ...this.bookEntry.amount < 0 ? ['red--text'] : []
            ]
          },
          {
            name: this.$t ('label.client'),
            value: !!this.bookEntry.clientId || EmptyMark,
            ...(
              this.bookEntry.clientId
                ? {
                  slotValue: this.$createElement (ClientLink, {
                    props: {
                      id: this.bookEntry.clientId
                    }
                  })
                }
                : {}
            )
          },
          ...this.bookEntry.subclientId && this.bookEntry.subclientId !== this.bookEntry.clientId
            ? [{
              name: this.$t ('label.subclient'),
              value: !!this.bookEntry.subclientId || EmptyMark,
              ...(
              this.bookEntry.subclientId
                ? {
                  slotValue: this.$createElement (ClientLink, {
                    props: {
                      id: this.bookEntry.subclientId
                    }
                  })
                }
                : {}
            )
            }]
            : [],
          ...this.bookEntry.objectName
            ? [{
              name: this.$t ('label.object'),
              link: this.objectLink (this.bookEntry),
              value: this.bookEntry.objectName
            }]
            : [],
          ...this.bookEntry.nsCount
            ? [{
              name: this.$t ('label.nsCount'),
              value: this.bookEntry.nsCount
            }]
            : [],
          {
            name: this.$t ('label.creationDate'),
            value: this.bookEntry.creationDate
              ? this.formatDate (this.bookEntry.creationDate)
              : EmptyMark
          },
          {
            name: this.$t ('label.date'),
            value: this.bookEntry.valutaDate
              ? this.formatDate (this.bookEntry.valutaDate)
              : EmptyMark
          },

          {
            name: this.$t ('label.accountBalance'),
            value: false,
            slotValue: this.$createElement (BalancesInfo, {
              props: {
                balances: this.bookEntry.balances,
                balanceAmount: this.bookEntry.overallAccountBalance,
                balanceCurrency: this.bookEntry.overallAccountBalanceCurrency,
                inaccurate: this.bookEntry.creationDate < referenceDate
              }
            })
          },

          ...this.bookEntry.years
            ? [{
              name: this.$t ('label.years'),
              value: this.bookEntry.years
            }]
            : [],

          ...this.bookEntry.refersTo
            ? [{
              name: this.$t ('label.refersTo'),
              value: !!this.bookEntry.refersTo || EmptyMark,
              ...(
              this.bookEntry.refersTo
                ? {
                  slotValue: this.$createElement (BookEntryLink, {
                    props: {
                      id: this.bookEntry.refersTo
                    }
                  },
                  [
                    this.$createElement ('span', {
                      slot: 'default'
                    }, [
                      this.bookEntry.refersTo
                    ])
                  ])
                }
                : {}
            )
            }]
            : [],

          ...this.bookEntry.refundedBy
            ? [{
              name: this.$t ('label.refundedBy'),
              value: !!this.bookEntry.refundedBy || EmptyMark,
              ...(
              this.bookEntry.refundedBy
                ? {
                  slotValue: this.$createElement (BookEntryLink, {
                    props: {
                      id: this.bookEntry.refundedBy
                    }
                  },
                  [
                    this.$createElement ('span', {
                      slot: 'default'
                    }, [
                      this.bookEntry.refundedBy
                    ])
                  ])
                }
                : {}
            )
            }]
            : [],

          ...this.bookEntry.exchangeRate
            ? [{
              name: this.$t ('label.exchangeRate'),
              value: !!this.bookEntry.exchangeRate || EmptyMark,
              ...(
              this.bookEntry.exchangeRate
                ? {
                  slotValue: this.$createElement (ConversionInfo, {
                    props: {
                      exchange: this.bookEntry.exchangeRate
                    }
                  })
                }
                : {}
            )
            }]
            : [],

          ...this.bookEntry.taxes
            ? [{
              name: this.$t ('label.vat'),
              value: (!!this.bookEntry.taxes && this.bookEntry.taxes.length) || this.$t ('label.noTax'),
              ...(
              this.bookEntry.taxes && this.bookEntry.taxes.length
                ? {
                  slotValue: this.$createElement (VatInfo, {
                    props: {
                      vats: this.bookEntry.taxes,
                      currency: this.bookEntry.currency
                    }
                  })
                }
                : {}
            )
            }]
            : [],

          ...this.bookEntry.memorandumText
            ? [{
              name: this.$t ('label.memorandumText'),
              value: this.bookEntry.memorandumText
            }]
            : [],

          ...this.bookEntry.transactionNumber
            ? [{
              name: this.$t ('label.transaction'),
              value: this.bookEntry.transactionNumber
            }]
            : [],

          ...this.bookEntry.nsName
            ? [{
              name: this.$t ('label.nsName'),
              value: this.bookEntry.nsName
            }]
            : [],

          ...this.bookEntry.chargeType
            ? [{
              name: this.$t ('label.chargeType.title'),
              value: this.$t (`label.chargeType.${this.bookEntry.chargeType}`)
            }]
            : [],
          ...this.bookEntry.usedTariffId
            ? [{
              name: this.$t ('label.usedTariffId'),
              value: this.bookEntry.usedTariffId
            }]
            : []
        ]
      },

      isAdmin () {
        return this.permissions.includes ('ManageAllObjects')
      }
    },

    watch: {
      id () {
        this.refundDialog = false
        this.loadBookEntry ()
      }
    },

    created () {
      this.loadBookEntry ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      loadBookEntry () {
        this.isLoading = true
        this.error = false
        this.bookEntry = null

        this.fetchData ({
          op: 'account/load-bookentry',
          params: {
            id: this.id
          },
          cb: data => {
            this.bookEntry = data.data
            if (this.bookEntry.bundleId && this.isAdmin) {
              this.loadBundle ()
            } else {
              this.bundle = []
            }
          },
          cbError: (data, next) => {
            this.error = true
            next (data)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      },

      objectLink ({objectName, objectType, objectId}) {
        let to

        if (objectName && objectId) {
          let name

          switch (objectType) {
            case 'Domain':
            case 'Contact':
            case 'Host':
              name = `${objectType.toLowerCase ()}.view.id`
              break
            default:
              return undefined
          }

          if (name) {
            to = {name, params: {id: objectId}}
          }
        }

        return to
      },

      onRefundClick () {
        this.refundDialog = true
      },

      cancelRefund () {
        this.refundDialog = false
      },

      onRefund ({recreate, refundBundle}) {
        this.isLoadingRefund = true
        this.fetchData ({
          op: 'account/refund-bookentry',
          params: {
            id: this.id,
            recreate,
            refundBundle
          },
          cb: () => {
            this.loadBookEntry ()
          },
          cbFinal: () => {
            this.isLoadingRefund = false
            this.cancelRefund ()
          }
        })
      },

      loadBundle () {
        this.isLoadingBundle = true

        this.fetchData ({
          op: 'account/list-bundled-bookentries',
          params: {
            id: this.bookEntry.bundleId
          },
          cb: (data) => {
            this.bundle = data.list
          },
          cbFinal: () => {
            this.isLoadingBundle = false
          }
        })
      }
    }
  }
</script>
